<template>
  <v-row v-if="transaction.items">
    <v-col cols="12">
      <h5 class="grey100--text text-body-2 mb-1">
        <span v-text="'Items received'" />
      </h5>
      <div class="d-flex flex-wrap">
        <GameItem
          v-for="(item, i) in items" :key="i"
          :item="item" no-item-color dollar-value
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    itemdb: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      const { itemdb } = this
      const { items } = this.transaction

      return items?.map(item => ({
        ...itemdb[item.name],
        price: item.price,
        amount: item.amount ?? 1,
      })) ?? []
    },
  },
}
</script>

<style lang="scss" scoped>
  .game-item {
    .v-image ::v-deep .v-responsive__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-amount {
      text-shadow: 0 0 2px black;
      user-select: none;
    }
  }
</style>
